import React, {Component} from "react"
import styles from "./Label.module.css"

interface Props {
    text: string
    additionaText?: string
    required?: boolean
    children: JSX.Element
    error?: string
    className?: string
    backendErrors?: string | null
}

export class Label extends Component<Props, {}> {
    public render() {
        const {
            children,
            text,
            error,
            required,
            className = "",
            backendErrors,
            additionaText
        } = this.props
        return (
            <label className={`${styles.root} ${className} ${error ? styles.highligth : ""}`}>
                {text && (
                    <p className={styles.text}>
                        {text}{" "}
                        {additionaText && (
                            <span className={styles.additionalText}>{additionaText}</span>
                        )}
                        {required && <span className={styles.required}> *</span>}
                    </p>
                )}
                {children}
                {error && (
                    <div className={styles.errorWrapper}>
                        <p className={styles.errorText}>{error}</p>
                    </div>
                )}
                {backendErrors && (
                    <div className={styles.errorWrapper}>
                        <p className={styles.errorText}>{backendErrors}</p>
                    </div>
                )}
            </label>
        )
    }
}
