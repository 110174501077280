import React, {Component} from "react"
import styles from "./Footer.module.css"
import imgLogo from "../Header/parts/img/intellitec-logo.png"

const campuses = [
    {
        id: 1,
        region_id: 1,
        name: "Albuquerque",
        code: "Intellitec",
        address: "4575 San Mateo Blvd NE Suite K",
        state: "Albuquerque, New Mexico 87109",
        phone: "(505) 508-5225",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses1 = [
    {
        id: 1,
        region_id: 1,
        name: "Colorado Springs",
        code: "Intellitec",
        address: "2315 East Pikes Peak Avenue",
        state: "Colorado Springs, CO 80909",
        phone: "(719) 632-7626",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses2 = [
    {
        id: 1,
        region_id: 1,
        name: "Grand Junction",
        code: "Intellitec",
        address: "772 Horizon Drive",
        state: "Grand Junction, CO 81506",
        phone: "(970) 245-8101",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses3 = [
    {
        id: 1,
        region_id: 1,
        name: "Pueblo",
        code: "Intellitec",
        address: "3673 Parker Blvd.",
        state: "Pueblo, CO 81008",
        phone: "(719) 542-3181",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]

const social = [
    {
        id: 1,
        name: "Twitter",
        link: "https://twitter.com/IntelliTecColl",
        icon: "fab fa-twitter"
    },
    {
        id: 2,
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/intellitec-college/",
        icon: "fab fa-linkedin"
    },
    {
        id: 3,
        name: "Youtube",
        link: "https://www.youtube.com/IntelliTecColleges",
        icon: "fab fa-youtube"
    },
    {
        id: 4,
        name: "TikTok",
        link: "https://www.tiktok.com/@intelliteccolleges",
        icon: "fab fa-tiktok"
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <div>
                        <div>
                            <img className={styles.logo} src={imgLogo} alt="intellitec-logo" />
                        </div>
                        <div className={styles.social}>
                            {social.map(({id, link, icon}) => (
                                <a
                                    href={link}
                                    className={icon}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {""}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses1.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses2.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses3.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </footer>
        )
    }
}
