import {validate} from "validate.js"
import {passwordStrength} from "check-password-strength"
// import {toJS} from "mobx"

export const CHECK_PASSWORD_OPTIONS = {
    contains: {
        lowercase: "lowercase",
        uppercase: "uppercase",
        number: "number",
        symbol: "symbol"
    },
    values: {
        tooWeek: "Too weak",
        weak: "Weak",
        medium: "Medium",
        strong: "Strong"
    },
    length: "length",
    id: "id",
    default: {
        contains: [],
        length: -1,
        value: "",
        id: -1
    }
}

export function findErrors(
    json_data,
    rules
): {isValid: boolean; errors: {[key: string]: string} | null} {
    let r = validate(json_data, rules)

    if (!r) {
        return {isValid: true, errors: {}}
    }
    let r2 = {...r}
    for (let key in r2) {
        r2[key] = r2[key][0]
    }
    console.log(r2)
    return {isValid: false, errors: r2}
}

export function findPasswordErrors(json_data): {isValid: boolean; options: any | null} {
    const {password} = json_data
    const passwordOptions = passwordStrength(password)
    const {value} = passwordOptions
    // console.log("@@@ passwordOptions:", toJS(passwordOptions))
    if (
        value === CHECK_PASSWORD_OPTIONS.values.strong ||
        value === CHECK_PASSWORD_OPTIONS.values.medium
    ) {
        return {isValid: true, options: passwordOptions}
    }
    return {isValid: false, options: passwordOptions}
}
