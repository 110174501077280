const ONE_TIME_PASSWORD_TYPES = {
    pin: "pin",
    otp: "otp",
    web: "web",
    webReset: "web-reset",
    signUp: "signup"
}

const DELIMITER = "<<@>>"

const getPartsByDelimiter = (value) => {
    return value.split(DELIMITER)
}

export {ONE_TIME_PASSWORD_TYPES, getPartsByDelimiter, DELIMITER}
