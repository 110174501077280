import React, {Component} from "react"
import styles from "./Header.module.css"
import imgLogo from "./parts/img/intellitec-logo.png"
import {ReactComponent as SignatureIcon} from "./parts/img/signature.svg"

interface Props {}

export class Header extends Component<Props, {}> {
    public render() {
        return (
            <header className={styles.root}>
                <div className={styles.wrapper}>
                    <h1 className={styles.h1}>
                        <img className={styles.logo} src={imgLogo} alt="intellitec-logo" />
                    </h1>
                    <div className={styles.rightWrapper}>
                        <SignatureIcon className={styles.signatureIcon} />
                        <span className={styles.text}>APPLY TO INTELLITEC</span>
                    </div>
                </div>
            </header>
        )
    }
}
