import React from "react"
import styles from "./OkIcon.module.css"
const OkIcon = (props) => (
    <div className={styles.root}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            role="img"
            aria-labelledby="circleOkIconTitle"
            stroke={props.color}
            strokeWidth="1"
            strokeLinecap="square"
            strokeLinejoin="miter"
            fill="none"
            color="#000000">
            {" "}
            <title id="circleOkIconTitle">OK</title> <polyline points="7 13 10 16 17 9" />{" "}
            <circle cx="12" cy="12" r="10" />{" "}
        </svg>
    </div>
)

export {OkIcon}
